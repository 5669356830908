<template>
  <ExportTracksPage :tracks-list-route-name="tracksListRouteName" :export-tracks-function="exportTracksFunction" />
</template>

<script>

import TrackService from '@/modules/Resorts/Services/TrackService'
import ExportTracksPage from "@/modules/Shared/Components/tracks/ExportTracksPage";

export default {
    name: 'resort-tracks-export-form',
    components: {
      ExportTracksPage
    },
    data () {
      return {
        tracksListRouteName: 'export-resort-tracks',
        exportTracksFunction: TrackService.exportTracks
      }
    }
}
</script>
